import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "notice-details"
};
const _hoisted_2 = {
  class: "notice-title"
};
const _hoisted_3 = {
  class: "notice-time"
};
const _hoisted_4 = ["innerHTML"];
import { router } from '@/router';
import { useUserStore } from '@/stores/user';
import { computed } from 'vue';
export default {
  __name: 'noticeDetails',
  setup(__props) {
    const userStore = useUserStore();
    const lang = computed(() => userStore.lang);
    const notice = computed(() => userStore.notice);
    const contentKey = computed(() => {
      return {
        'en_US': 'content',
        'fr_FR': 'fr_FR_content',
        'es_ES': 'es_ES_content',
        'it_IT': 'it_IT_content',
        'vi': 'vt_VT_content'
      }[lang.value];
    });
    const titleKey = computed(() => {
      return {
        'en_US': 'name',
        'fr_FR': 'fr_FR_name',
        'es_ES': 'es_ES_name',
        'it_IT': 'it_IT_name',
        'vi': 'vt_VT_name'
      }[lang.value];
    });
    const back = () => {
      router.go(-1);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString(notice.value[titleKey.value]), 1), _createElementVNode("p", _hoisted_3, _toDisplayString(notice.value.created_at), 1), _createElementVNode("div", {
        innerHTML: notice.value[contentKey.value]
      }, null, 8, _hoisted_4)]);
    };
  }
};